import { current } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { Card, Container } from "src/styles/globalcomponents";
import { BlackFridayCounterStyled, CountdownButtonStyled, CountdownSectionStyled, CountdownTitleStyled, CountdownWrapperStyled } from "./blackFridayCounter.styled";
import { config } from "src/config";

interface BlackFridayCounterProps {
    date: Date
    onClickCountdown: () => void
    onCountdownEnds: () => void
}

function BlackFridayCounter({ date, onCountdownEnds, onClickCountdown }: BlackFridayCounterProps) {
    const [timeRemaining, setTimeRemaining] = useState<number>(0);

    function formatTime(time: number) {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const days = Math.floor(time / (1000 * 60 * 60 * 24));

        return (
            <BlackFridayCounterStyled backgroundUrl={`${config.urls.cdnOptimized}/platform/black-friday/black-friday-bg.webp?format=webp&width=500`}>
                <img width={300} height={220} src={`${config.urls.cdnOptimized}/platform/black-friday/black-friday-tag.png?format=png&width=300`} alt="Black Friday llegó" />
                <Container flexDirection="column" alignItems="center" gap="1.5rem">
                    <CountdownTitleStyled>
                        COMIENZA EN:
                    </CountdownTitleStyled>
                    <CountdownWrapperStyled>
                        <CountdownSectionStyled>
                            <article>
                                <div>{days.toString().padStart(2, "0")[0]}</div>
                                <div>{days.toString().padStart(2, "0")[1]}</div>
                            </article>
                            <span>Días</span>
                        </CountdownSectionStyled>
                        <CountdownSectionStyled>
                            <article>
                                <div>{hours.toString().padStart(2, "0")[0]}</div>
                                <div>{hours.toString().padStart(2, "0")[1]}</div>
                            </article>
                            <span>Horas</span>
                        </CountdownSectionStyled>
                        <CountdownSectionStyled>
                            <article>
                                <div>{minutes.toString().padStart(2, "0")[0]}</div>
                                <div>{minutes.toString().padStart(2, "0")[1]}</div>
                            </article>
                            <span>Minutos</span>
                        </CountdownSectionStyled>
                        <CountdownSectionStyled>
                            <article>
                                <div>{seconds.toString().padStart(2, "0")[0]}</div>
                                <div>{seconds.toString().padStart(2, "0")[1]}</div>
                            </article>
                            <span>Segundos</span>
                        </CountdownSectionStyled>
                    </CountdownWrapperStyled>
                    <CountdownButtonStyled>
                        Conocer Oferta
                    </CountdownButtonStyled>
                </Container>
            </BlackFridayCounterStyled>
        );
    }

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (date) {
                const currentTime = new Date().getTime()
                const eventTime = date.getTime()
                let remainingTime = eventTime - currentTime

                if (remainingTime <= 0) {
                    remainingTime = 0
                    clearInterval(countdownInterval)
                    onCountdownEnds()
                }

                setTimeRemaining(remainingTime)
            }
        }, 1000)

        return () => clearInterval(countdownInterval)
    }, [onCountdownEnds, date]);

    return (<Card onClick={onClickCountdown} style={{ cursor: "pointer" }}>
        {formatTime(timeRemaining)}
    </Card>);
}

export default BlackFridayCounter;