import { instance, subsInstance } from "../axios/axiosConfig";
import {
	addonsAvailablePlans,
	addonsOldPlans,
} from "../pages/profile/subscription/plan/tabs/planDescriptionTab/additionalTool/addons.data";
import {
	EmailToolAccountResponse,
	GetSubscriptionStateResponseNew,
	UserSubscriptionStateNewestResponse,
} from "../interfaces/plans";

export async function ExecuteActivationSubProductPayment({
	email,
	codePlan,
	idProduct,
	amount,
}: { email: string; codePlan: string; idProduct: number; amount: number }) {
	const response = await subsInstance.post(`suscription/subproduct/approved`, {
		doc_client: "",
		email,
		code_plan: codePlan,
		id_product: idProduct,
		transaction_info: {
			amount,
			currency_code: "USD",
		},
	});

	return response.data;
}

export async function GetUserEmailToolContactsConsumed(idUser: string) {
	const response = await instance.get(`/app/addons/account-status/${idUser}`);
	return response.data.data as EmailToolAccountResponse;
}

export async function GetUserSubscriptionAddonState({
	email,
	planCode,
	productId,
}: { email: string; planCode: string; productId?: number }) {
	const validationResponse = await instance.post(
		`/app/suscription/validateAction`,
		{
			email,
			planCode,
		},
	);

	if (!validationResponse.data.success)
		throw new Error("Error validating addons subscription");

	const validationData = (
		validationResponse.data as UserSubscriptionStateNewestResponse
	).data;

	const planData: GetSubscriptionStateResponseNew = {
		isActivation:
			validationData.action === "Upgrade" ||
			validationData.action === "Downgrade",
		isApportionmentPrice: Boolean(validationData.amount),
		apportionmentPrice: validationData.amount,
		action: validationData.action,
		startDate: validationData.startDate.split("T")[0] as string,
		finalDate: validationData.finalDate.split("T")[0] as string,
		priceTotal: validationData.amount,
		codePlanPaytool: planCode,
		valueToCharge: undefined,
		periodicity: "Mensual",
		isSubProduct: validationData.isSubProduct,
	};

	if (validationData.action === "Upgrade") {
		planData.isChangePlan =
			validationData.action === "Upgrade" ||
			validationData.action === "Downgrade";
		return planData;
	}

	if (validationData.action === "Renew") {
		return planData;
	}

	return planData;
}

export const getCurrentAddonPlan = (userContacts: string | number) => {
	const contactQuantity =
		typeof userContacts === "string"
			? Number.parseInt(userContacts)
			: userContacts;
	const addData = addonsAvailablePlans.filter(
		(addon) => addon.contacts === contactQuantity,
	);

	return addData[0];
};

export const getCurrentAddonPlanOld = (userContacts: string | number) => {
	const contactQuantity =
		typeof userContacts === "string"
			? Number.parseInt(userContacts)
			: userContacts;
	const addData = addonsOldPlans.filter(
		(addon) => addon.contacts === contactQuantity,
	);

	return addData[0];
};
