export const config = {
  urls: {
    res: "https://cdn.bemaster.com/mastertools",
    cdnOptimized: "https://img.master.la/mastertools",
    fbCommunity: "https://www.facebook.com/groups/mastertools.lat",
    env: "https://localhost:3000",
    chat: "https://mastertools.com/chat",
    copyToolUrl: process.env.REACT_APP_COPYTOOL_URL || "",
    connecToolUrl: process.env.REACT_APP_CONNECTOOL_URL || "",
    linkToolUrl: process.env.REACT_APP_LINKTOOL_URL || "",
    mastershopUrl: process.env.REACT_APP_MASTERSHOP_URL ?? ""
  },
  axios: {
    url: process.env.REACT_APP_URL_API || "",
    urlSubscriptions: process.env.REACT_APP_SUBSCRIPTION_API || "",
    urlConnecToolApi: process.env.REACT_APP_CONNECTOOL_API_URL || "",
    admUrl: process.env.REACT_APP_URL_ADM_API || "",
    copyToolUrl: process.env.REACT_APP_COPYTOOL_URL || "",
    activeCampaignPartnerUrl: process.env.REACT_APP_ACTIVE_CAMPAIGN_PARTNER_API || ''
  },
  regex: {
    // eslint-disable-next-line
    email:
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
    hasUppercase: /[A-Z]/g,
    hasLowercase: /[a-z]/g,
    hasNumber: /[0-9]/g,
  },
  accounts: {
    defaultCodeActivation: "HP13716292436706",
  },
  authorization: {
    linktoolAuthToken: process.env.REACT_APP_LINKTOOL_AUTH_TOKEN,
    activeCampaignPartnerApiKey: process.env.REACT_APP_ACTIVE_CAMPAIGN_PARTNER_API_KEY
  },
  connecttoolIntegrations: {
    google: {
      clientId: process.env.REACT_APP_GOOGLE_INTEGRATION_CLIENT_ID,
      clientSecret: process.env.REACT_APP_GOOGLE_INTEGRATION_CLIENT_SECRET
    }
  },
  secrets: {
    updatePaymentMethod: process.env.REACT_APP_SECRET_PAYMENT_METHOD_KEY
  }
};
