import { useState } from "react";
import { CloseableModal } from "src/components/ui/modal/closeable-modal.component";
import type { PlanFrequency } from "src/interfaces/plans";
import { TextButton } from "src/pages/login/login.styles";
import { LeftArrowIcon } from "../../../../../components/icons";
import { BackButtonStyled } from "../../../../../components/ui/backButton/index.styles";
import { useAppSelector } from "../../../../../hooks/useRedux";
import { Card, Container, ContainerItem } from "../../../../../styles/globalcomponents";
import { ConfirmPlanTab } from "../../plan/purchase/tabs/confirmPlanTab/confirmPlan.tab";
import { FinishPurchaseModal } from "../../plan/purchase/tabs/finishPurchase.modal";
import { PLANS_PERIODICITY_HIERARCHY } from "../../plan/tabs/plans.data";
import { UnsubscribeButton } from "../../subscription.styles";
import {
    TitleStyled
} from "../unsubscribe.styles";
import RecommendedPlanCard from "./recommendedPlanCard.component";

interface Props {
    onAbortCancellation: () => void
    onStartCancellation: () => void
}

export function RecommendedPlansDowngrade({ onAbortCancellation, onStartCancellation }: Props) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const currPlan = dataUser.suscription
    const plans = useAppSelector((state) => state.auth.plans);
    const planHasLowerOptions = PLANS_PERIODICITY_HIERARCHY[dataUser.suscription.typeSuscription as PlanFrequency] > 1

    return (
        <><Card
            padding={"0 2.5rem 2rem"}
            position={"relative"}
        >
            <Container
                margin={"auto"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                padding={"2rem 0 0 "}
            >
                <BackButtonStyled onClick={onAbortCancellation}>
                    <LeftArrowIcon fill={"white"} />
                </BackButtonStyled>
                <TitleStyled>
                    Tu suscripción no tiene que terminar: Descubre alternativas
                </TitleStyled>
                <span>¿Qué tal si cambias tu plan de frecuencia de pago? Estas son algunas de las opciones disponibles para ti.</span>
                <ContainerItem alignItems={"center"} display="flex" flexDirection="row" gap="1.5rem" justifyContent="center" margin="3rem 0">
                    {!planHasLowerOptions && <h2>No hay opciones para disminución de plan 😭</h2>}
                    {
                        planHasLowerOptions && Object.keys(PLANS_PERIODICITY_HIERARCHY).map(periodicity => {
                            const isALowerPlanThanCurrent = PLANS_PERIODICITY_HIERARCHY[periodicity as PlanFrequency] < PLANS_PERIODICITY_HIERARCHY[currPlan.typeSuscription as PlanFrequency]

                            return plans.map(plan => {
                                const isSamePlanWithLowerPeriodicity = plan.suscriptionInfo.name === currPlan.name && isALowerPlanThanCurrent
                                if (!isSamePlanWithLowerPeriodicity) return
                                return <RecommendedPlanCard periodicity={periodicity as PlanFrequency} plan={plan} key={plan.suscriptionInfo.idSuscription} />
                            })
                        })
                    }
                </ContainerItem>
                <UnsubscribeButton
                    onClick={onStartCancellation}
                >
                    Cancelar suscripción
                </UnsubscribeButton>
                <TextButton onClick={onStartCancellation}><p>Continuar con la cancelación</p></TextButton>
            </Container>
        </Card>

        </>
    )
}
