import axios from "axios";
import { config } from "../config";


const instance = axios.create({
  baseURL: config.axios.url,
});

const subsInstance = axios.create({
  baseURL: config.axios.urlSubscriptions,
});

const connecToolInstance = axios.create({
  baseURL: config.axios.urlConnecToolApi,
});

export { instance, subsInstance, connecToolInstance };
