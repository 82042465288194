export interface State {
		showRecommendedPlansDowngrade: boolean;
		showOverview: boolean;
		showDowngradeVideo: boolean;
		showUnSubscribe: boolean;
		showPlans: boolean;
		isEmailCancellation: boolean;
		onlyAdditionalTools: boolean;
	}
type ActionType =
	| "showRecommendedPlansDowngrade"
	| "showUnsubscribeVideo"
	| "showDowngrade"
	| "showOverview"
	| "showPlans";
type ActionPayload = {
	isEmailCancellation: boolean;
	onlyAdditionalTools: boolean;
};
interface Action {
	type: ActionType;
	payload?: ActionPayload;
}

const CLEAN_STATE: State = {
	showRecommendedPlansDowngrade: false,
	showOverview: false,
	showDowngradeVideo: false,
	showUnSubscribe: false,
	showPlans: false,
	isEmailCancellation: false,
	onlyAdditionalTools: false,
};

export function SubscriptionTabReducer(
	state: State,
	{ type, payload }: Action,
) {
	const actionsType: Record<ActionType, State> = {
		showRecommendedPlansDowngrade: {
			...CLEAN_STATE,
			showRecommendedPlansDowngrade: true,
		},
		showUnsubscribeVideo: {
			...CLEAN_STATE,
			showUnSubscribe: true,
			isEmailCancellation: !!payload && payload.isEmailCancellation,
		},
		showDowngrade: {
			...CLEAN_STATE,
			showDowngradeVideo: true,
		},
		showOverview: {
			...CLEAN_STATE,
			showOverview: true,
		},
		showPlans: {
			...CLEAN_STATE,
			showPlans: true,
			onlyAdditionalTools: !!payload && payload.onlyAdditionalTools,
		},
	};
	return actionsType[type] ?? state;
}
