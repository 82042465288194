import { CgProfile, CgTrash } from "react-icons/cg";
import styled from "styled-components";
import { config } from "../../../config";
import { globalstyles } from "../../../styles/animations/globalstyles";

export const PlanTool = styled.img`
  width: 24px;
  height: 24px;
`;

export const PlanTools = styled.div`
  display: flex;
  grid-gap: 4px;
  height: 24px;
`;

export const PlanTitle = styled.div`
  font-size: 1.1rem;
  font-weight: bolder;
  color: white;
`;
export const PlanSubtitle = styled.span`
  font-size: .8rem;
  font-weight: 600;
  color: white;
`;

interface EmailDescriptionProps {
  bolder?: boolean;
}

export const EmailDescription = styled.div<EmailDescriptionProps>`
  font-size: 18px;
  color: white;
  font-weight: ${(props) => props.bolder && "bolder"};
`;

export const EmailText = styled.div`
  font-size: 1rem;
  color: white;
  font-weight: bolder;
`;

export const EmailIcon = styled.img`
  width: 32px;
  height: 32px;
`;

interface props {
  bannerImg?: string;
}

export const EmailImg = styled.div<props>`
  background-image: ${(props) =>
    props.bannerImg
      ? `url(${config.urls.res}/platform/banners/${props.bannerImg})`
      : `url(${config.urls.res}/platform/Franja-03.png)`};
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  place-items: center;
  width: 100%;
`;

interface props {
  flexDirection?: string;
  plan?: string;
}

export const PlanImg = styled.div<props>`
  align-items: center;
  background-image: ${(props) =>
    props.plan
    ? `url(${config.urls.res}/platform/banners/banner_${props.plan}.png)`
      : `url(${config.urls.res}/platform/Franja-05.png)`};
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: ${(props) => props.flexDirection ? props.flexDirection : "bolder"};
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const TrashIcon = styled(CgTrash)`
  margin: 4px;
  width: 16px;
  height: 16px;
`;

export const ProfileIcon = styled(CgProfile)`
  margin: 4px;
  width: 16px;
  height: 16px;
`;

export const MastertoolsLogo = styled.img`
  height: 32px;
  position: absolute;
  margin: auto;
  top: 40%;
  left: 16px;
`;

export const Background = styled.img`
  width: 100%;
  position: absolute;
  z-index: -1;
`;
